import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import Layout from "../components/Layout/Layout";
import parse from "html-react-parser";
//@ts-ignore
import Minus from "../images/minus.svg";
//@ts-ignore
import Plus from "../images/plus.svg";
import { useState } from "react";
//@ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb";

//@ts-ignore
const frequentlyAskedQuestions = ({ intl, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <Layout>
      <div className="grid xs:justify-center  sm:pl-0   bg-[#FAFAFA] pb-[10rem]">
        <div className=" flex flex-col sm:grid-rows-2 xs:grid-rows-3 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
          <div className="mt-[2.5rem] mb-[2.5rem]">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator="/"
              crumbLabel={parse(
                intl.formatMessage({
                  id: "FAQ.Title",
                })
              )}
            />
          </div>
          <h2 className=" sm:text-[2rem] xs:text-[1.75rem] font-semibold leading-9 text-[#001439]">
            {parse(
              intl.formatMessage({
                id: "FAQ.Title",
              })
            )}
          </h2>
          <div className="mt-[2rem] w-auto h-auto py-[1.5rem] px-[1.5rem] bg-white border border-[#618EE4] border-opacity-25 rounded-[1rem]">
            <div className="flex justify-between">
              <h2 className="sm:auto xs:w-[80%] font-semibold sm:text-[1.75rem] xs:text-[1.25rem]">
                {parse(
                  intl.formatMessage({
                    id: "FAQ.HowToGetResultOfAnalysis.Question",
                  })
                )}
              </h2>
              {showMoreInfo ? (
                <img
                  src={Minus}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => setShowMoreInfo(false)}
                />
              ) : (
                <img
                  src={Plus}
                  alt=""
                  className="cursor-pointer"
                  onClick={() => setShowMoreInfo(true)}
                />
              )}
            </div>
            {showMoreInfo ? (
              <ul className="mt-[2rem] font-regular text-[1.25rem] list list-disc list-inside">
                <p className="mb-[1rem]">
                  {parse(
                    intl.formatMessage({
                      id: "FAQ.HowToGetResultOfAnalysis.AnswerTitle",
                    })
                  )}
                </p>
                {parse(
                  intl.formatMessage({
                    id: "FAQ.HowToGetResultOfAnalysis.AnswerSubtitle",
                  })
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
          <div className="mt-[2rem] w-auto h-auto py-[1.5rem] px-[1.5rem] bg-white border border-[#618EE4] border-opacity-25 rounded-[1rem]">
            <div className="flex justify-between">
              <h2 className="sm:auto xs:w-[80%] font-semibold sm:text-[1.75rem] xs:text-[1.25rem]">
                {parse(
                  intl.formatMessage({
                    id: "FAQ.WhyDoYouNeedHouseCall.Question",
                  })
                )}
              </h2>
              <img src={Minus} alt="" className="cursor-pointer hidden" />
              <img src={Plus} alt="" className="cursor-pointer " />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(frequentlyAskedQuestions);
